// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/ArialRoundedMtExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Mattilda.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/BalsamiqSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/BalsamiqSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ArialRoundedMtExtraBold";
  /*Can be any text*/
  src: local("ArialRoundedMtExtraBold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Mattilda";
  /*Can be any text*/
  src: local("Mattilda"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  /*Can be any text*/
  src: local("Poppins-Light"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  /*Can be any text*/
  src: local("Poppins-Medium"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
@font-face {
  font-family: "BalsamiqSans-Bold";
  /*Can be any text*/
  src: local("BalsamiqSans-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}
@font-face {
  font-family: "BalsamiqSans-Regular";
  /*Can be any text*/
  src: local("BalsamiqSans-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB;8DAC+D;AACjE;AACA;EACE,uBAAuB;EACvB,kBAAkB;EAClB;8DACgD;AAClD;AACA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB;8DACqD;AACvD;AACA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB;8DACsD;AACxD;AACA;EACE,gCAAgC;EAChC,kBAAkB;EAClB;8DACyD;AAC3D;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB;8DAC4D;AAC9D","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: \"ArialRoundedMtExtraBold\";\n  /*Can be any text*/\n  src: local(\"ArialRoundedMtExtraBold\"),\n    url(\"./fonts/ArialRoundedMtExtraBold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Mattilda\";\n  /*Can be any text*/\n  src: local(\"Mattilda\"),\n    url(\"./fonts/Mattilda.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins-Light\";\n  /*Can be any text*/\n  src: local(\"Poppins-Light\"),\n    url(\"./fonts/Poppins-Light.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins-Medium\";\n  /*Can be any text*/\n  src: local(\"Poppins-Medium\"),\n    url(\"./fonts/Poppins-Medium.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"BalsamiqSans-Bold\";\n  /*Can be any text*/\n  src: local(\"BalsamiqSans-Bold\"),\n    url(\"./fonts/BalsamiqSans-Bold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"BalsamiqSans-Regular\";\n  /*Can be any text*/\n  src: local(\"BalsamiqSans-Regular\"),\n    url(\"./fonts/BalsamiqSans-Regular.ttf\") format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
