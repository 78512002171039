import React, { useState } from 'react';
import Math from '../../../Assets/icons pink/math-activities-icon.png';
import Writing from '../../../Assets/icons pink/writing-skills-icon_.png';
import Book from '../../../Assets/icons pink/vocabulary building icon_.png';
import Brain from '../../../Assets/icons pink/cognitive ability icon_.png';
import Memory from '../../../Assets/icons pink/memory-retention-icon_.png';
import Creative from '../../../Assets/icons pink/creative thnking icon_.png';
// ====================================HoverImage==========================================
import hoverMath from '../../../Assets/icons white/math activities icon.png';
import hoverWriting from '../../../Assets/icons white/writing skills icon_.png';
import hoverBook from '../../../Assets/icons white/vocabulary building icon_.png';
import hoverBrain from '../../../Assets/icons white/cognitive ability icon_.png';
import hoverMemory from '../../../Assets/icons white/memory retention icon_.png';
import hoverCreative from '../../../Assets/icons white/creative thnking icon_.png';
import './Adventure.less'
import { useWindowSize } from "../../Common/Common";
// import FourthSection from './ParentsFindsKidskite'


const data = [
  {
    key: "1",
    image: Math,
    hoverImage: hoverMath,
    heading: "Math Activities",
    description: "Master fundamental math concepts through an engaging and playful approach.",
  },
  {
    key: "2",
    image: Writing,
    hoverImage: hoverWriting,
    heading: "Writing Skills",
    description: "From tracing letters to forming words, KidsKite nurture the development of early writing skills.",
  },
  {
    key: "3",
    image: Book,
    hoverImage: hoverBook,
    heading: "Vocabulary Building",
    description: " Expand your kid's vocabulary and enhance language skills in a playful manner.",
  },
  {
    key: "4",
    image: Brain,
    hoverImage: hoverBrain,
    heading: "Cognitive Ability",
    description: "KidsKite lays a strong foundation for your young one's mental development.",
  },
  {
    key: "5",
    image: Memory,
    hoverImage: hoverMemory,
    heading: "Memory Retention",
    description: "Sharpen your child's memory skills with entertaining memory games.",
  },
  {
    key: "6",
    image: Creative,
    hoverImage: hoverCreative,
    heading: "Creative Thinking",
    description: "Encourage creativity and imaginative thinking through interactive challenges.",
  },
];

const AdventureCard = ({ image, hoverImage, heading, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`adventure-card ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="adventure-card-img">
        <img src={isHovered ? hoverImage : image} alt="AdventureImage" className="adventure-img" />
      </div>
      <div className="adventure-card-head">{heading}</div>
      <div className="adventure-card-description">{description}</div>
    </div>
  );
};
const ThirdPart = () => {
  const [width] = useWindowSize();

  return (
    <div className="adventure-container" id="about-us">
      <div>
        <div className="Adventure-heading" style={{ textAlign: 'center' }}>
          MAKE YOUR KID'S LEARNING
          <br />AN ADVENTURE WITH KIDSKITE
        </div>

        {width <= 576 && (
          <div className="Adventure-heading-mobile" style={{ textAlign: 'center' }}>
            MAKE YOUR KID'S <br />LEARNING
            AN ADVENTURE<br /> WITH KIDSKITE
          </div>
        )}
      </div>


      <div className="adventure-desktop">
        {data.map((item) => (
          <AdventureCard key={item.key} {...item} />
        ))}
      </div>
      {/* <FourthSection /> */}
    </div>
  );
};
export default ThirdPart;
