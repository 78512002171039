import React, { useRef, useState } from 'react';
import './ContactForm.less';
import ThankyouImg from '../../../Assets/thankyou.png';
// import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';


const ContactForm = () => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const contactRef = useRef(null);
    const messageRef = useRef(null);
    const websiteNameRef = useRef(null);
    const toEmailRef = useRef(null);

    const [showFormContent, setShowFormContent] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Access form inputs using refs
        const formData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            contact: contactRef.current.value,
            message: messageRef.current.value,
            websiteName: websiteNameRef.current.value,
            toEmail: toEmailRef.current.value,
        };
    
        console.log('Form Data:', formData);
    
        // Correct usage of emailjs.sendForm
        emailjs.sendForm('service_60mrwi1', 'template_grbqp4k', e.target, 'gAWHpl3Tddh-gc9UU');
    
        setShowFormContent(false);
    
        window.addEventListener('beforeunload', () => {
            setShowFormContent(true);
          });
    };
    

    return (
        <div className='formcontainer'  id='contact-us'>
            <h1>CONTACT US !</h1>
            <form onSubmit={handleSubmit} className={`formdiv ${showFormContent ? '' : 'hide-form-content'}`}>
                {showFormContent && (
                    <>
                        <label>
                            <span style={{color:'red'}}>*</span>Full Name
                            <input type="text" name="name" ref={nameRef} required />
                        </label>
                        <br />
                        <label>
                        <span style={{color:'red'}}>*</span>Email
                            <input type="email" name="email" ref={emailRef} required />
                        </label>
                        <label>
                        <span style={{color:'red'}}>*</span>Mobile Number
                            <input type="contact" name="contact" ref={contactRef} required />
                        </label>
                        <br />
                        <label>
                        <span style={{color:'red'}}>*</span>Leave us a message
                            <input name="message" ref={messageRef} required className='messageInput' />
                        </label>
                        <label style={{ display: 'none' }}>
                            websiteName
                            <input name="websiteName" ref={websiteNameRef} required defaultValue={'Kidskite-Website'} />
                        </label>
                        <label style={{ display: 'none' }}>
                            toEmail
                            <input name="toEmail" ref={toEmailRef} required className='messageInput' defaultValue={'support@kidskite.app'} />
                        </label>
                        <br />
                        <button type="submit">Submit</button>
                    </>
                )}

                {!showFormContent && (
                    <div className="imageContainer bounce" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={ThankyouImg} style={{ width: '70%', height: '60%' }} alt="Thank you" />
                    </div>
                )}

            </form>
        </div>
    );
};

export default ContactForm;
