// JSX code

import React, { useState, useEffect } from 'react';
import Form from './ContactForm';
import footerImg from '../../../Assets/footer updated.png';
import mobileFooterImg from '../../../Assets/mobile/footer.png'

import youtubeImg from '../../../Assets/youtube.png';
import instagramImg from '../../../Assets/instagram.png';
import facebookImg from '../../../Assets/facebook.png';
import playstoreImg from '../../../Assets/playstore.png';
import teddyDialogImg from '../../../Assets/dog with dialogue.png'
import DialogImgTeddy from '../../../Assets/teddy-with-dialogue.png'
import kattyDialogImg from '../../../Assets/catty_with_dialogue.png'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';


import './Footer.less';


const Footer = () => {
    let navigate = useNavigate();


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const teddyImages = [
        teddyDialogImg,
        DialogImgTeddy,
        kattyDialogImg,
        teddyDialogImg,
        DialogImgTeddy,
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % teddyImages.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentImageIndex, teddyImages]);

    return (
        <div className='footer'>
            <div className='teddyandformParent'>
                <div className='formParent'>
                    <Form />
                </div>
                <div className='parentTeddy'>
                    <div className='TeddyImg'>
                        {teddyImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Teddy Dialog ${index + 1}`}
                                className={`teddy-dialog ${index === currentImageIndex ? 'active' : ''}`}
                                style={{
                                    transition: `transform 1s ease-in-out, opacity 1s ease-in-out`, // Apply transition for both transform and opacity
                                    transform: `translateX(-${(currentImageIndex % teddyImages.length) * 100}%)`,
                                    opacity: index === currentImageIndex ? 1 : 0, // Set opacity to 0 for non-active images
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="image-container2" >
                <img className='desktopfooterimg'
                    src={footerImg}
                    style={{ maxWidth: '100%', height: "100%" }}
                />
                <img className='mobilefooterimg'
                    src={mobileFooterImg}
                    style={{ width: '100%', height: "100%" }}
                />
                <div className='lastfooter' >

                    <div className='column column1'>
                        <p className='desktopPara'>
                            KidsKite is a learning app for kids aged 2-7, offering play-based<br />
                            learning through educational games. The main mission is to turn<br /> your kids' screen time into smart time and make their early<br /> learning an exciting adventure.
                        </p>
                        <p className='mobilePara'>
                            KidsKite is a learning app for kids aged 2-7, offering play-based
                            learning through educational games. The main mission is to turn your kids' screen time into smart time and make their early learning an exciting adventure.
                        </p>
                    </div>
                    <div className='column column2'>
                        <div className='socialicon'>
                            <a href="https://www.youtube.com/@KidsKiteLearning" target="_blank" rel="noopener noreferrer">
                                <img src={youtubeImg} alt="YouTube" className='social' style={{ width: '50px', height: '55px' }} />
                            </a>
                            {/* You can add similar anchor tags for other social media platforms */}
                            {/* <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
        <img src={twitterImg} alt="Twitter" style={{ width: '50px', height: '55px' }} />
    </a> */}
                            <a href="https://www.facebook.com/kidskitelearning/" target="_blank" rel="noopener noreferrer">
                                <img src={facebookImg} alt="Facebook" className='social' style={{ width: '50px', height: '55px' }} />
                            </a>
                            <a href="https://www.instagram.com/kidskitelearning/" target="_blank" rel="noopener noreferrer">
                                <img src={instagramImg} alt="Instagram" className='social' style={{ width: '50px', height: '55px' }} />
                            </a>
                            {/* <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
        <img src={linkdinImg} alt="LinkedIn" style={{ width: '50px', height: '55px' }} />
    </a> */}
                            {/* <a href="https://www.whatsapp.com/" target="_blank" rel="noopener noreferrer">
        <img src={whatsappImg} alt="WhatsApp" style={{ width: '50px', height: '55px' }} />
    </a> */}
                        </div>

                    </div>
                    <div className='column column3'>
                        <a href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1" target="_blank" rel="noopener noreferrer">
                            <img
                                src={playstoreImg}
                                alt="Playstore"
                                className='playstore'
                                style={{ width: '100px', height: '100px' }} // Adjust the width and height as needed
                            />
                        </a>
                    </div>

                </div>
                <div className='lastNavbar'>
                    <Link to="/" className='navbarItem'
                        onClick={() => { window.scrollTo(0, 0); }}>
                        HOME
                    </Link>
                    <Link to="/privacy-policy" className='navbarItem'
                        onClick={() => { window.scrollTo(0, 0); }}>
                        PRIVACY POLICY
                    </Link>
                    <Link to="/terms-and-conditions" className='navbarItem'
                        onClick={() => { window.scrollTo(0, 0); }}>
                        TERMS & CONDITIONS
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
