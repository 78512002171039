// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/App.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,uBAAA;AAAF;AAGA;EACE,UAAA;EACA,SAAA;EACA,YAAA;AADF","sourcesContent":["// @import \"~antd/dist/antd.less\";\nhtml {\n  height: 100%;\n  scroll-behavior: smooth;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  height: 100%;\n}\n\n\n// * {\n//   font-family: \"Montserrat\", sans-serif;\n// }\n@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
