import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";

import Img from '../../../Assets/Thumbnails/youtube thumbnail addition.png';
import Img2 from '../../../Assets/Thumbnails/division easy thumbnail.png';
import Img3 from '../../../Assets/Thumbnails/multiplication easy thumbnail.png';
import Img4 from '../../../Assets/Thumbnails/subtraction easy thumbnail.png';

import Img5 from '../../../Assets/Thumbnails/black sheep thumbnail.png';
import Img6 from '../../../Assets/Thumbnails/abc-thumbnail-(small).png';
import Img7 from '../../../Assets/Thumbnails/thumbnail 123.png';
import Img8 from '../../../Assets/Thumbnails/spelling_game_mdium_thumbnail.png';
import Img9 from '../../../Assets/Thumbnails/shape_puzzle_thumbnail.png';
import Img10 from '../../../Assets/Thumbnails/Match_making_easy_thumbnail.png';
import Img11 from '../../../Assets/Thumbnails/youtube thumbnail addition.png';
import youtubeEmoji from '../../../Assets/youtube-icon.png';


import "./YTmobile.less";

import { useWindowSize } from "../../Common/Common"

const youtubeData = [
  // {
  //   key: 1,
  //   youtubeThumbnail: Img,
  //   heading: 'Basic Addition For Kids',
  //   description: 'Easy Addition E-learning Game',
  //   youtubeEmoji: youtubeEmoji,
  //   videoUrl: 'https://www.youtube.com/watch?v=er-h4d5X6kw'
  // },
  // {
  //   key: 2,
  //   youtubeThumbnail: Img2,
  //   heading: 'Basic Division For Kids',
  //   description: 'Easy Division E-learning Game',
  //   youtubeEmoji: youtubeEmoji,
  //   videoUrl: 'https://www.youtube.com/watch?v=s-5wEu2hCvA'
  // },
 
  {
    key: 5,
    youtubeThumbnail: Img5,
    heading: 'Nursery Rhymes For Kids',
    description: 'Baa Baa Black Sheep Poem',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=d01ONZqZIx8'
  },
  {
    key: 6,
    youtubeThumbnail: Img6,
    heading: 'ABC Writing For Kids',
    description: 'Learn To Write a-z English Letters',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=pHHxFX0fN0I&t=3s'
  },
  {
    key: 7,
    youtubeThumbnail: Img7,
    heading: 'Write Numbers 1-50',
    description: 'Learn To Form Numbers 123',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=p0H2zogICZ4&t=7s'
  },
  {
    key: 8,
    youtubeThumbnail: Img8,
    heading: 'Learn To Spell Words',
    description: "Build Your Kid's Vocabulary",
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=nRkGdpEFfHs&t=11s'
  },
  {
    key: 9,
    youtubeThumbnail: Img9,
    heading: 'Shapes For Kids',
    description: 'Learn the Shapes of Different Objects ',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=4-rwSWS4aDk&t=35s'
  },
  {
    key: 10,
    youtubeThumbnail: Img10,
    heading: 'Matching & Logic Games',
    description: "Test Your Kid's Visual Memory",
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=KztuMNwNMT0'
  },
  {
    key: 11,
    youtubeThumbnail: Img11,
    heading: 'Addition Exercise for Kids',
    description: 'Learn To Add While Playing Games',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=er-h4d5X6kw&t=3s'
  },
  {
    key: 3,
    youtubeThumbnail: Img3,
    heading: 'Easy Multiplication For Kids ',
    description: 'Practice Basic Multiplication',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=4XHw1m3sRpY'
  },
  {
    key: 4,
    youtubeThumbnail: Img4,
    heading: 'Practice Subtraction',
    description: 'Fun Subtraction Game For Kids',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=a0r6aGwuDBk'
  },
];

const PageNine = () => {
  const [width, height] = useWindowSize();

  const [slideIndex, setSlideIndex] = useState(1);
  const [autoPlay, setAutoPlay] = useState(true);

  const moveDot = (index) => {
    setSlideIndex(index);
  };
  const [updateanimation, setupdateanimation] = useState(1);

  return (
    <div className="aPageNine_bg" style={{ overflow: "hidden" }} onLoad={() => setupdateanimation(2)} id="youtubeLink">
      <div className="head">
        <div className="aPageNine_heading">  EXPLORE OUR YOUTUBE
          CHANNEL CONTENT</div>
      </div>

      {/* <div className="MobileAboutUsSwiper"> */}
      <Swiper
        // data-aos="fade-up"
        slidesPerView={width > 992 ? 3 : 1}
        spaceBetween={0}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}

        pagination={{
          // enabled: false,
          clickable: true,
          bulletClass: `swiper-pagination-bullet`,
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        
        className="aPageNine_mySwiper_Mobile"
        style={{
          width: width > 1200 ? "95%" : width > 992 ? "98%" : "100%",
        }}
        centeredSlides={width > 576 ? true : true}
      >
        {youtubeData &&
          youtubeData.map((item, index) => (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <div
                  className="aPageNine_SwiperContainer_Mobile"
                  style={{
                    transform: isActive && width > 576 ? "scale(1)" : "scale(1)",
                  }}
                >
                  <a href={item.videoUrl} target="_blank" rel="noopener noreferrer" className="card_img_client">
                    <img src={item.youtubeThumbnail} alt="" />
                  </a>
                  <div className="cards_content">
                    <h1>{item.heading}</h1>
                    <p>{item.description}</p>
                    <div className="youtubeThumbnailContainer">
                      <a href={item.videoUrl} target="_blank" rel="noopener noreferrer">
                        <img src={item.youtubeEmoji} alt="YouTube" className="youtubeThumbnail" />
                      </a>
                    </div>
                  </div>

                </div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>

    </div>
  );
};



export default PageNine;