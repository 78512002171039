// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tacPageTwoBody {
  padding: 20px 40px;
}
.tacPageTwoSubTitle {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #4e7884;
  line-height: 1.3;
  text-transform: capitalize;
}
.tacPageTwoContent {
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  color: #4e7884;
  text-align: justify;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 1.3;
  margin-bottom: 40px;
}
.hyperLink {
  word-wrap: break-word;
}
@media screen and (max-width: 576px) {
  .tacPageTwoBody {
    padding: 8px 25px 0px 19px;
  }
  .tacPageTwoSubTitle {
    font-size: 22px;
    font-weight: 700;
  }
  .tacPageTwoContent {
    margin-bottom: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Terms&Condition/TermsAndConditions.less"],"names":[],"mappings":"AAIA;EAGI,kBAAA;AALJ;AAQA;EACI,eAAA;EACA,gBAAA;EAEA,mBAAA;EACA,cAAA;EAEA,gBAAA;EACA,0BAAA;AARJ;AAYA;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AAVJ;AAaA;EACI,qBAAA;AAXJ;AAcA;EACI;IACI,0BAAA;EAZN;EAcE;IACI,eAAA;IACA,gBAAA;EAZN;EAcE;IACI,mBAAA;EAZN;AACF","sourcesContent":[".tacPageTwoBg {\n    // display: grid;\n}\n\n.tacPageTwoBody {\n    // margin-top: -20px;\n    // margin-bottom: 40px;\n    padding: 20px 40px;\n}\n\n.tacPageTwoSubTitle {\n    font-size: 28px;\n    font-weight: 700;\n    // margin-top: 50px;\n    margin-bottom: 10px;\n    color: #4e7884;\n    // text-align: justify;\n    line-height: 1.3;\n    text-transform: capitalize;\n    // text-decoration: underline;\n}\n\n.tacPageTwoContent {\n    font-size: 15px;\n    font-weight: 500;\n    margin-left: 5px;\n    color: #4e7884;\n    text-align: justify;\n    word-wrap: break-word;\n    hyphens: auto;\n    line-height: 1.3;\n    margin-bottom: 40px;\n}\n\n.hyperLink {\n    word-wrap: break-word;\n}\n\n@media screen and (max-width: 576px) {\n    .tacPageTwoBody {\n        padding: 8px 25px 0px 19px;\n    }\n    .tacPageTwoSubTitle {\n        font-size: 22px;\n        font-weight: 700;\n    }\n    .tacPageTwoContent {\n        margin-bottom: 40px;\n    }\n}\n@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
