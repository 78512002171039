import React, { useEffect } from 'react';
import "./Redirection.less";

const Redirection = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent;

        let appStoreUrl, playStoreUrl;

        // Determine the user's device type
        if (userAgent.includes('Android')) {
            playStoreUrl = 'https://play.google.com/store/apps/details?id=app.freakx.kidskite';
            window.location.href = playStoreUrl;
        } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
            appStoreUrl = 'https://kidskite.app/';
            window.location.href = appStoreUrl;
        } else {
            // Redirect to a default page for unsupported devices
            window.location.href = 'https://play.google.com/store/apps/details?id=app.freakx.kidskite';
        }
    }, []);

    return (
        <div className="redirecting-container">
            <p className="redirecting-text">Redirecting to store...</p>
        </div>
    );
};

export default Redirection;
