// HeaderUI.jsx

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../Assets/logoH.png";
import HLines from "../Common/HLines";
import "./Header.less";
// import { Link } from "react-scroll";

const HeaderUI = (props) => {
  let navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleHashChange = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const offset = -83; // Set the desired margin-bottom
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const scrollToPosition = elementPosition + offset;
  
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });
  
      setMobileMenuOpen(false); // Close the mobile menu after clicking a link
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div>
      {/* Desktop Header */}
      <header className="kidskite-header desktop-header">
        <nav className="kidskite-nav">
          <div className="kidskite-logoDiv">
            <a href='/'>
              <img src={logo} alt="logoBtn" className="kidskite-logoStyling" />
            </a>
          </div>
          <div>
            <div className="navbar">
              <Link to="/#about-us" smooth={true} duration={500} onClick={() => { handleHashChange('about-us') }}>
                <span className="navbar_item">
                  <div>About Us</div>
                </span>
              </Link>
              <Link to="/#benefits" smooth={true} duration={500} onClick={() => { handleHashChange('benefits') }}>
                <span className="navbar_item">
                  <div>Benefits</div>
                </span>
              </Link>
              <Link to="/#whykidskite" smooth={true} duration={500} onClick={() => { handleHashChange('whykidskite') }}>
                <span className="navbar_item">
                  <div>Why Us</div>
                </span>
              </Link>
              <Link to="/#youtubeLink" smooth={true} duration={500} onClick={() => { handleHashChange('youtubeLink') }}>
                <span className="navbar_item">
                  <div>Videos</div>
                </span>
              </Link>
              <Link to="/#contact-us" smooth={true} duration={500} onClick={() => { handleHashChange('contact-us') }}>
                <span className="navbar_item">
                  <div>Contact Us</div>
                </span>
              </Link>
            </div>
          </div>
        </nav>
      </header>

      {/* Mobile and Tablet Header */}
      <header className={`kidskite-header mobile-tablet-header ${mobileMenuOpen ? 'open' : ''}`}>
        <nav className="kidskite-nav">
          <div className="kidskite-logoDiv">
            <a href='/'>
              <img src={logo} alt="logoBtn" className="kidskite-logoStyling" />
            </a>
          </div>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </nav>
        {mobileMenuOpen && (
          <div className="mobile-menu">
            <Link to="/#about-us" smooth={true} duration={500} onClick={() => { handleHashChange('about-us') }}>
              <div>About Us</div>
            </Link>
            <Link to="/#benefits" smooth={true} duration={500} onClick={() => { handleHashChange('benefits') }}>
              <div>Benefits</div>
            </Link>
            <Link to="/#whykidskite" smooth={true} duration={500} onClick={() => { handleHashChange('whykidskite') }}>
              <div>Why Us</div>
            </Link>
            <Link to="/#youtubeLink" smooth={true} duration={500} onClick={() => { handleHashChange('youtubeLink') }}>
              <div>Videos</div>
            </Link>
            <Link to="/#contact-us" smooth={true} duration={500} onClick={() => { handleHashChange('contact-us') }}>
              <div>Contact Us</div>
            </Link>
          </div>
        )}
      </header>
    </div>
  );
};

export default HeaderUI;
