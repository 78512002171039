// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hLine {
  width: 100%;
  height: 1.6px;
  background: #21ce86;
  opacity: 0.3;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Common/HLines.less"],"names":[],"mappings":"AAMA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AALF","sourcesContent":["@brand-primary: #21ce86;\n@brand-secondary: #4e7884;\n@brand-ternary: #185563;\n@brand-quaternary: #029989;\n@brand-white: #ffffff;\n\n.hLine {\n  width: 100%;\n  height: 1.6px;\n  background: @brand-primary;\n  opacity: 0.3;\n}\n\n@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
