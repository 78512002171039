import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.less';

import Header from './Component/Header/Header'

import AllPage from './Component/HomePageSection/AllPage';
import TermsAndConditions from "./Component/Terms&Condition/TermsAndConditions";
import PrivacyPolicy from "./Component/PrivPolicy/PrivacyPolicy";
import Redirection from "./Component/Redirection/Redirection";


import '../src/fonts/ArialRoundedMtExtraBold.ttf';
import '../src/fonts/Mattilda.ttf';
import '../src/fonts/Poppins-Light.ttf';
import '../src/fonts/Poppins-Medium.ttf';
import '../src/fonts/BalsamiqSans-Bold.ttf';
import '../src/fonts/BalsamiqSans-Regular.ttf';
// import '../src/fonts/BalsamiqSans-Medium.ttf';

function App() {

    const location = window.location;
    const [showHeader, setShowHeader] = useState(true);

    useEffect(() => {
        // Check if current path is "/redirect-to-app"
        if (location.pathname === "/redirect-to-app") {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }
    }, [location]);

    return (
        <div style={{ width: "100%" }}>
            <BrowserRouter>
                {showHeader && (
                    <div style={{ position: 'sticky', top: '0', zIndex: '999' }}>
                        <Header />
                    </div>
                )}
                {/* <ALLSectionHomePage /> */}
                <Routes>
                    <Route path="/" element={<AllPage />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/redirect-to-app" element={<Redirection />} />
                </Routes>

                {/* <Footer /> */}
            </BrowserRouter>
        </div>
    );
}

export default App;
