import React, { useLayoutEffect , useEffect } from "react";
import "./PrivacyPolicy.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../Common/Common";
// import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useNavigate, Link as HasLink } from "react-router-dom";
import logo from "../../Assets/logoH.png";
import { Link } from "react-scroll";



const PageTwo = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Set the document title when the component mounts
        document.title = "Privacy Policy";
    
        // Clean up function to reset the title when the component unmounts
        return () => {
          document.title = "Kidskite";
        };
      }, []);

    return (
        <div>
            <div className="tacPageTwoBg" style={{ overflow: "hidden" }}>
                <div className="tacPageTwoBody">
                    {/* 1 */}
                    <div className="tacPageTwoContent">
                        <p>
                            At Gamesfly Technologies Private Limited, ("the Company," "We," "Us," "Our," or "KidsKite"), we take the protection of your personal information seriously and respect your right to privacy. This Privacy Notice explains how we collect and handle your personal data, where we might get this data from, and who we might share it with.
                            When you give us your information or use the services, mobile app, or other services we offer, you agree to let KidsKite collect, keep, process, and share your personal and non-personal information as described in this Privacy Policy. You also agree that this won't harm you or anyone else.
                            This Privacy Policy is an agreement between you and KidsKite. It should be read along with KidsKite's Terms of Use or other service conditions. Ensure to read the full Privacy Policy before using or providing information about these services.
                        </p>
                    </div>
                    {/* 2 */}
                    <div className="tacPageTwoSubTitle">
                        What kind of information do we collect?
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            Here's the kind of personal data we collect through our website:
                        </p>
                        <p>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Phone Number</li>
                            <li>Email Address</li>
                            <li>Location (City, Country)</li>
                            <li>Activity Data (like IP address and how you use our website)</li>
                        </p>
                        <p>
                            Remember, if you share someone else's personal data with us, it's your responsibility to make sure you have their permission.
                        </p>
                        <p>
                            We might get personal information about you from other places like social media, publicly available sources, and our business partners. If you use our services through a social media platform or link our services to one, we may collect details like your social media username, any info the social media lets us have, and anything you've made public on that platform. By using our services through social media or linking them, you're giving KidsKite permission to gather, keep, use, and hold onto this information as per this Privacy Policy.
                        </p>
                        <p>
                            KidsKite might also gather non-personal information when you visit or use our services. This type of information is kept in server logs. We collect it in different ways, like using cookies. KidsKite can place temporary or permanent cookies on your computer. You have the option to delete or block these cookies. You can set your browser to let you know when we're sending a cookie, and you can choose whether to accept it or not. But remember, if you turn off cookies, some parts of our services might not work properly for you.
                        </p>
                        <p>
                            You ensure to KidsKite that the data provided by you is accurate and current, and that you have the necessary rights and permissions to reveal it to KidsKite. Remember that by sharing with us this information, you're not breaking any laws, agreements, or orders.
                        </p>
                        <p>
                            In any case, KidsKite and its team cannot be held responsible for checking whether your data is authentic or not. If you give us information that's not true, you'll need to bear any costs or damages causing KidsKite, its team, or anyone else who relied on your details.
                        </p>
                    </div>
                    {/* 3 */}
                    <div className="tacPageTwoSubTitle">
                        How do we process children's data?
                    </div>
                    <div className="tacPageTwoContent">

                        <p>We take your kid's privacy seriously. We don't knowingly gather personal data from them but data such as their full name, age may get collected. However, it's important to note that these data attributes are stored only on the user's device and are not accessible by us.</p>

                        <p>If your child is under 16, please don't let him/her use our website. Parents have the option to review any personal information about their child that may have been collected. If you're a parent or guardian and wants to get your child's data deleted, you can request the removal of their data and ask that any future use of such data be avoided by emailing our privacy team at <span style={{ color: 'blue' }}>support@kidskite.app.</span>
                        </p>
                    </div>
                    {/* 4 */}
                    <div className="tacPageTwoSubTitle">
                        Why do we use your data?
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            We collect your data for numerous reasons:
                        </p>
                        <ol>
                            <li>To let you sign up for KidsKite.</li>
                            <li>To provide support and assistance.</li>
                            <li>To check for your subscription with KidsKite.</li>
                            <li>To aware our customers about new features if any, changes to our website and services, user agreements, promotional offers, etc. through emails.</li>
                            <li>To study user behavior such as looking at visitor numbers, traffic, user preferences, and overall patterns.</li>
                            <li>To share data analytics with third parties of KidsKite, such as statistics about traffic patterns, users, and other aspects.</li>
                            <li>To make our services better, which includes research, testing, developing new products and analyzing data.</li>
                            <li>To meet legal obligations and provide information to law enforcement agencies or for investigations related to public safety, as allowed by law.</li>
                            <li>To inquire, prevent, or act on unlawful activities.</li>
                            <li>To process any job applications you submit.</li>
                        </ol>
                        <p>These pieces of information collected is for legal purposes related to an activity of KidsKite or someone acting on its behalf. You hereby understand that collecting these details is necessary for the reasons outlined.</p>
                    </div>
                    {/* 5 */}
                    <div className="tacPageTwoSubTitle">
                        How do we protect your data?
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            We make our best efforts to avoid any unauthorized use or accidental harm of your personal information. And to do this, we leverage a mix of technical, physical, and administrative safeguards.
                        </p>
                        <li>We strictly follow security and privacy practices.</li>
                        <li>We ensure to encrypt data during transfer or while stored.</li>
                        <li>We limit access to your information only to those who need to know.</li>
                    </div>
                    {/* 6 */}
                    <div className="tacPageTwoSubTitle">
                        How do we disclose your data with others?
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            Sometimes, it is necessary to share the information you have provided with outside parties like hosting companies, communication agencies, IT firms, technical service providers, and mail carriers. At that time, we ensure that they are following privacy laws by signing Data Processing Agreements. Please note that we don't allow these external parties to track the children's behavior.
                        </p>
                        <p>
                            For additional information about our appointed external parties, please refer to the details below
                        </p>
                    </div>
                    {/* 7 */}
                    <div className="tacPageTwoSubTitle">
                        Analytics
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            The external parties mentioned in this section help us monitor and analyze web traffic, allowing us to track user behavior.
                        </p>

                        <p><span style={{ fontWeight: "bold" }}>Google Analytics (Google LLC)- </span> Google Analytics is our analytics software. Google uses the data collected to monitor and analyze the usage of our website, generate reports on its activities, and share them with other Google services. Personal data collected here would be your IP Address.</p>

                        <p>To learn more about their personal data processing practices, please review their  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.</p>
                    </div>
                    {/* 8 */}
                    <div className="tacPageTwoSubTitle">
                        Customer Subscription Management
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            We employ a customer subscription management solution that aids us in validating and managing user subscriptions. Additionally, it enables us to address support requests from users regarding their subscriptions.
                        </p>
                        <p><span style={{ fontWeight: "bold" }}>RevenueCat- </span> RevenueCat is our customer subscription management software. It permits us to monitor and analyze user behavior and purchase history. Personal data collected here would be your first and last name and email address. </p>

                        <p>To learn more about their personal data processing practices, please review their  <a href="https://www.revenuecat.com/dpa/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                    </div>
                    {/* 9 */}
                    <div className="tacPageTwoSubTitle">
                        SPAM Protection                    </div>
                    <div className="tacPageTwoContent">
                        <p>This service analyzes the traffic of this website, which may include users' personal data, to filter out parts of traffic, messages, and content identified as SPAM.</p>

                        <p><span style={{ fontWeight: "bold" }}>Google reCAPTCHA (Google LLC)-</span> Google reCAPTCHA is our SPAM protection software.</p>
                        <p>To learn more about their personal data processing practices, please review their  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>

                    </div>
                    {/* 10 */}
                    <div className="tacPageTwoSubTitle">
                        Managing Contacts and Sending Messages
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            This type of service allows us to manage a database of email contacts, or any other contact information for communication with the user.
                        </p>
                        <li><span style={{ fontWeight: "bold" }}>Brevo (SendinBlue)-</span> Brevo is a service for managing email addresses and sending messages. It may collect data regarding the date and time when the message was viewed by the user and when the user interacted with it, such as by clicking on links included in the message. Personal data collected here would be your email address.</li><br />
                        <p>To learn more about their personal data processing practices, please review their  <a href="https://www.brevo.com/legal/privacypolicy/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                    </div>
                    {/* 11 */}
                    <div className="tacPageTwoSubTitle">
                        Payment Processing
                    </div>
                    <div className="tacPageTwoContent">
                      <p>
                            All transactions related to subscriptions are processed through the Google Play Store, and Gamesfly Technologies Private Limited does not collect or store any payment information.
                            Please refer to Google's
                            <a href="https://support.google.com/googleplay/android-developer/answer/9900533/" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'blue'}}>
                                &nbsp; Payment Information Policies&nbsp;
                            </a>
                            for more details on how payment information is handled.
                            </p>

                        {/* <p>
                            We utilize external payment service providers to handle payments for our services.
                        </p>
                        <li><span style={{ fontWeight: "bold" }}>Stripe -</span> Stripe is our payment service provider. They collect the necessary payment card information for payment processing. We do not directly collect your payment information. Instead, we receive notifications from the relevant payment service provider indicating whether the payment has been successfully completed. Personal data collected here would be your first and last name, email address, and billing information.</li><br />
                        <p>To learn more about their personal data processing practices, please review their  <a href="https://stripe.com/en-in/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p> */}
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Information Collection and Use
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            Google Play Subscriptions
                        </p>
                        <p>
                            We offer two subscription plans within Kidskite, available for purchase on the Google Play Store:
                        </p>

                        <li>6-Month Subscription: Users can opt for a 6-month subscription plan, billed half-yearly.</li>
                        <li>1-Year Subscription: Users can opt for a 1-year subscription plan, billed annually.</li>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Hosting and Backend Infrastructure
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            This type of service is designed to host data and files necessary for the functioning and distribution of the website. It also provides a pre-configured infrastructure for running specific features or components of the website.
                        </p>
                        <p> <span style={{ fontWeight: "bold" }}>GoDaddy -</span>
                            GoDaddy is our hosting service. To learn more about their personal data processing practices, please review their   <a href="https://stripe.com/en-in/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                        <p> <span style={{ fontWeight: "bold" }}>Amazon EC2 (AWS) -</span>
                            Amazon EC2 is our backend service. To learn more about their personal data processing practices, please review their <a href="https://stripe.com/en-in/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                        <p> <span style={{ fontWeight: "bold" }}>Amazon Simple Storage Service (AWS S3) -</span>
                            Amazon S3 is our database storage service. To learn more about their personal data processing practices, please review their<a href="https://stripe.com/en-in/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Registration/Authentication
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            With the registration process, users give permission to KidsKite to identify them and provide access to the services.
                        </p>
                        <p><span style={{ fontWeight: "bold" }}>Firebase Authentication (Google LLC) -</span> Firebase is our registration and authentication solution provider. Personal data collected here would be your first and last name, email address, and phone number.</p>
                        <p>To learn more about their personal data processing practices, please review their  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Managing Support and Contact Requests
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            This service manages the contact requests that we receive from Zoho Desk.
                        </p>

                        <p><span style={{ fontWeight: "bold" }}>Zoho Desk -</span> Zoho Desk is a service for managing support and contact requests. Personal data collected here would be your first and last name and email address (provided voluntarily).</p>

                        <p>To learn more about their personal data processing practices, please review their  <a href="https://www.zoho.com/privacy.html" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}> Privacy Policy</a>.
                        </p>
                        <p>By agreeing to this, you allow us to share, disclose, and transfer your personal and non-personal details. Doing so won't harm you or others, nor will it unfairly help us or any third party</p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Retention of your data
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            We'll keep your personal data as long as needed for the purposes it was collected. Sometimes, we might hold onto your data for longer if the law allows or requires it, like to meet legal, tax, or regulatory needs, deal with disputes, or defend or assert legal claims. The length of time we keep your data depends on legal requirements and our business needs. After this period, we'll securely dispose of your personal data.
                        </p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Rights of users
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            We value your rights and privacy, and we ensure your personal data is correct and up-to-date.
                        </p>
                        <p>
                            Here's what you can do:
                        </p>

                        <li>You have the right to find out the personal data we have of you.</li>
                        <li>You have the right to withdraw your permission from us to process your personal data.</li>
                        <li>You can update your personal information whenever required to make sure it's accurate and complete.</li>
                        <li>You can stop us from using your personal data for direct marketing.</li>
                        <li>You can ask for a copy of your personal data.</li>

                    </div>
                    <div className="tacPageTwoSubTitle">
                        How to Use Your Rights?
                    </div>
                    <div className="tacPageTwoContent">
                        <p>To use your rights, you can contact us at <span style={{ color: 'blue' }}>support@kidskite.app.</span>.</p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        How to Delete Your Account?
                    </div>

                    <div className="tacPageTwoContent">
                        <p>If you want to delete your account, just email us at <span style={{ color: 'blue' }}>support@kidskite.app.</span>
                            If you're not happy with how we've handled your request or you have a complaint about how we process your personal data, you can reach out to the relevant Data Protection Authority (DPA). For more information, please email us at <span style={{ color: 'blue' }}>support@kidskite.app.</span>
                        </p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Security of your data
                    </div>
                    <div className="tacPageTwoContent">
                        <p>We work hard to keep your information safe and private, making sure your personal information is protected from unauthorized access, changes, sharing, or destruction. We have proper measures in place to stop unauthorized people from getting to your personal information.
                        </p>

                        <p>We have the right to check your identity at any time for security reasons. You need to give us any information we ask for during this check. If you don't follow our security requests, we may have to close your account and stop you from using our services.
                        </p>

                        <p>We are not responsible and cannot be held accountable for any security breaches or for the actions of any third parties that get your personal information.
                        </p>

                        <p>The trademarks, logos, and service marks shown on our services ("Marks") belong to KidsKite. You must not use these Marks without first getting permission from KidsKite.
                        </p>

                        <p>Regardless of what is mentioned in this policy or elsewhere, we cannot be held liable for any loss, damage, or misuse of your personal information if it occurs due to a Force Majeure Event.
                        </p>
                        <p>A "Force Majeure Event" refers to any situation outside our reasonable control, including but not limited to sabotage, fires, floods, explosions, acts of nature, public disturbances, strikes or other labor disputes, riots, rebellions, wars, government actions, computer hacking, unauthorized access to computer data and storage devices, computer crashes, breaches of security and encryption, and similar events.
                        </p>

                        <p>We are not responsible and cannot be held accountable for any security breaches or for the actions of any third parties that get your personal information.
                        </p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Opt-Out Options: Managing Your Choices
                    </div>
                    <div className="tacPageTwoContent">
                        <p>You understand and agree that you are giving your information voluntarily. You always have the choice not to share your personal information with KidsKite, or you can change your decision later and revoke your consent. To do this, just send an email to <span style={{ color: 'blue' }}>support@kidskite.app.</span>.</p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Updates to the privacy policy
                    </div>
                    <div className="tacPageTwoContent">
                        <p>KidsKite has the right to change, modify, or update this Privacy Notice whenever we need to. The most recent version of the Privacy Notice will always replace any older versions. We encourage you to regularly check this Privacy Notice to stay up-to-date with any changes. If any of these changes affect how we process information based on your consent, we'll ask for your new consent if necessary.</p>
                    </div>
                    <div className="tacPageTwoSubTitle">
                        Contacting KidsKite
                    </div>
                    <div className="tacPageTwoContent">
                        <p>
                            Gamesfly Technologies Private Limited
                        </p>
                        <p>
                            5th floor, H.B Twin Tower, Netaji Subhash Place,<br />
                            Pitampura, Delhi 110034<br />
                            Email Address: <span style={{ color: 'blue' }}>support@kidskite.app</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PageTwo;