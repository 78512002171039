import React from "react";


import BenefitBtn1 from '../../../Assets/Illustrations/00.png'
import BenefitBtn2 from '../../../Assets/Illustrations/01.png'
import BenefitBtn3 from '../../../Assets/Illustrations/02.png'
import BenefitBtn4 from '../../../Assets/Illustrations/03.png'
import BenefitBtn5 from '../../../Assets/sample teddy icon_.png'
import BenefitBtn6 from '../../../Assets/sample teddy icon_.png'

// import FifthSection from '../HomePage/UnlockEndless'

import "./ParentsFindsKidskite.less";
import { Row } from "antd";

const FourthSection = () => {
    return (
        <div className="FourthSectionBackground" id='benefits'>
            <div className="Fourth_Section_benefits" style={{ textAlign: "center" }}>
                WHY PARENTS FIND KIDSKITE EXCEPTIONAL?
            </div>

            <div className="FourthSection_cards_div">
                <Row style={{ marginTop: 26 }} justify="space-around" gutter={[0, 30]}>
                    <Row>
                        <div className="outerCard"
                            style={{
                                display: 'flex',
                                justifyContent: "center",
                                // alignItems: "center",
                                border: '2px dashed orange',
                            }}
                        >
                            <div className="FourthSection_img_card">
                                <img src={BenefitBtn1} alt="BenefitBtn1" />
                            </div>
                            <div className="FourthSection_card_body">
                                <div className="mobileCard">
                                    <div className="FourthSection_img_card_mobile">
                                        <img src={BenefitBtn1} alt="BenefitBtn1" width={100} height={100} />
                                    </div>
                                    <div>
                                        <div className="Fourth_Section_title_one_mobile" >Interactive Educational Platform</div>
                                    </div>
                                </div>
                                <div className="desktopCard">
                                    <span className="Fourth_Section_title_one" style={{ marginLeft: '55px' }}>Interactive Educational &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform</span>
                                </div>
                                <p className="desktopCardContent" style={{ marginLeft: '55px' }}>On KidsKite, kids get to learn through<br /> playing games, making learning enter-<br />taining and interactive while exploring<br /> new concepts.</p>

                                <p className="mobileCardContent">On KidsKite, kids get to learn through playing games, making learning entertaining and interactive while exploring new concepts.</p>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="outerCard"
                            style={{
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: "center",
                                border: '2px dashed #de3a8c',
                            }}
                        >

                            <div className="FourthSection_card_body">
                                <div className="mobileCard">
                                    <div className="FourthSection_img_card_mobile">
                                        <img src={BenefitBtn2} alt="BenefitBtn1" width={100} height={100} />
                                    </div>
                                    <div>
                                        <div className="Fourth_Section_title_one_mobile">Variety of Kids E-learning Games</div>
                                    </div>
                                </div>
                                <div className="desktopCard" style={{ textAlign: 'end', marginTop: '-50px' }}>
                                    <span className="Fourth_Section_title_one">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Variety of Kids<br />E-learning Games
                                    </span>
                                </div>
                                <p className="desktopCardContent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KidsKite offers diverse educational<br /> games that make learning an exciting<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;adventure for your little ones.</p>

                                <p className="mobileCardContent">KidsKite offers diverse educational
                                    games that make learning an exciting
                                    adventure for your little ones.</p>
                            </div>
                            <div className="FourthSection_img_card">
                                <img src={BenefitBtn2} alt="BenefitBtn2" />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="outerCard"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                // alignItems: "center",
                                border: '2px dashed blue'
                            }}
                        >
                            <div className="FourthSection_img_card">
                                <img src={BenefitBtn3} alt="BenefitBtn3" />
                            </div>
                            <div className="FourthSection_card_body">
                                <div className="mobileCard">
                                    <div className="FourthSection_img_card_mobile">
                                        <img src={BenefitBtn3} alt="BenefitBtn3" width={100} height={100} />
                                    </div>
                                    <div>
                                        <div className="Fourth_Section_title_one_mobile">Track your child's progress</div>

                                    </div>
                                </div>
                                <div className="desktopCard">
                                    <span className="Fourth_Section_title_one" style={{ marginLeft: '55px' }}>Track your <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;child's progress</span>

                                </div>
                                <p className="desktopCardContent" style={{ marginLeft: '50px' }}>Monitor your child's growth through our progress-tracking feature and celebrate each milestone achieved.</p>

                                <p className="mobileCardContent">Monitor your child's growth through our progress-tracking feature and celebrate each milestone achieved.</p>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="outerCard"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                // alignItems: "center",
                                border: '2px dashed green'
                            }}
                        >

                            <div className="FourthSection_card_body">
                                <div className="mobileCard">
                                    <div className="FourthSection_img_card_mobile">
                                        <img src={BenefitBtn4} alt="BenefitBtn1" width={100} height={100} />
                                    </div>
                                    <div>
                                        <div className="Fourth_Section_title_one_mobile">Ad-free and Kid-safe Content</div>

                                    </div>
                                </div>
                                <div className="desktopCard" style={{ textAlign: 'end' }}>
                                    <span className="Fourth_Section_title_one">Ad-free and <br />Kid-safe Content</span>

                                </div>
                                <p className="desktopCardContent" style={{ textAlign: 'right' }}>We believe in fostering a safe and<br /> distraction free environment, allowing your kid to focus on the joy of learning.</p>

                                <p className="mobileCardContent">We believe in fostering a safe and
                                    distraction free environment, allowing your kid to focus on the joy of learning.</p>
                            </div>
                            <div className="FourthSection_img_card" style={{ marginLeft: '100px' }}>
                                <img
                                    src={BenefitBtn4}
                                    alt="BenefitBtn4"
                                />
                            </div>
                        </div>
                    </Row>
                </Row>
            </div>
            {/* <FifthSection /> */}
        </div>
    );
};

export default FourthSection;
