import React, {useEffect} from "react";

import "./TermsAndConditions.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../Common/Common";
// import React from "react";

import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logoH.png";
import HLines from "../Common/HLines";
// import "./Header.less";
import { Link } from "react-router-dom";



const PageTwo = (props) => {
    let navigate = useNavigate();
    const [width, height] = useWindowSize();

    useEffect(() => {
        // Set the document title when the component mounts
        document.title = "Terms & Conditions";
    
        // Clean up function to reset the title when the component unmounts
        return () => {
          document.title = "Kidskite";
        };
      }, []);

    return (
        <div className="tacPageTwoBg" style={{ overflow: "hidden" }}>
            <div className="tacPageTwoBody">
                {/* 1 */}
                <div className="tacPageTwoContent">
                    <p>
                        Welcome to KidsKite, an educational application ("App") created by Gamesfly Technologies Pvt Ltd ("Company," "we," "us," "our"). The App provides educational games and activities for children aged 2-7, focusing on foundational skills development in a fun and engaging way.
                    </p>
                </div>
                <div className="tacPageTwoSubTitle">
                    Acceptance of Terms
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        By accessing or using the KidsKite App, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please do not use the App.
                    </p>
                    <p>
                        We reserve the right to modify these Terms at any time. Your continued use of the App following any such modifications signifies your acceptance of the updated Terms.
                    </p>
                </div>
                {/* 2 */}
                <div className="tacPageTwoSubTitle">
                    Eligibility
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        The Services are not accessible to:
                    </p>
                    <p>
                        1) Any users who have been suspended or banned from the Services by KidsKite in the past.
                    </p>
                    <p>
                        2) Any individuals under the age of 13 (or under the legal age of consent in your country) whose registration has not been authorized by a parent or legal guardian.
                    </p>
                    <p>
                        3) For school accounts, registration must be approved by school personnel as allowed under the Terms.
                    </p>
                </div>
                {/* 3 */}
                <div className="tacPageTwoSubTitle">
                    User Account
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        1) Account Creation: You have the option to access and utilize the KidsKite services either by registering as a user or by using the app as a guest.
                    </p>
                    <li><span style={{ fontWeight: "bold" }}>Registered Users:</span> To access certain features of the App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li><br />
                    <li><span style={{ fontWeight: "bold" }}>Guest Users:</span> Guest users may not have access to all sections of the App. KidsKite offers limited access to the services for users who choose not to register. These individuals can browse the landing pages of the app to assess whether they wish to register for full access.</li> <n />

                    <p>2) Account Responsibility: You are responsible for safeguarding your account password and for any activities or actions under your account. If you think someone else might be using your account without your permission (for example, in case of loss, theft, or unauthorized access of your account ID or password), you agree to promptly inform KidsKite at <span style={{ color: 'blue' }}>support@kidskite.app.</span> We cannot and will not be liable for any loss or damage arising from your failure to comply with this requirement.</p>
                    <p>Using another user's account information to access the services is strictly forbidden. If you provide information that is false, incorrect, outdated, or incomplete, or if KidsKite has valid reasons to believe that the provided information is false, incorrect, outdated, or incomplete, KidsKite reserves the authority to suspend or deactivate your account and reject any present or future utilization of the App.</p>
                </div>
                {/* 4 */}
                <div className="tacPageTwoSubTitle">
                    Use of the App
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        1) <span style={{ fontWeight: "bold" }}>License Grant:</span> We grant you a limited, non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes, subject to these Terms.
                    </p>
                    <p>
                        2) <span style={{ fontWeight: "bold" }}>Prohibited Uses:</span> You agree not to use the App for any unlawful purpose or in any way that interrupts, damages, impairs, or renders the App less efficient. You agree not to attempt to gain unauthorized access to the App, the server on which the App is stored, or any server, computer, or database connected to the App.
                    </p>
                </div>
                {/* 5 */}
                <div className="tacPageTwoSubTitle">
                    Intellectual Property Rights
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        1) <span style={{ fontWeight: "bold" }}>Ownership:</span> All intellectual property rights in the App, including but not limited to its trademark, logo, design, text, graphics, and the selection and arrangement thereof, are and remain the property of Gamesfly Technologies Private Limited or its licensors.
                    </p>
                    <p>
                        2) <span style={{ fontWeight: "bold" }}>Restrictions:</span> You may not copy, modify, create derivative works, reverse engineer, decompile, or otherwise attempt to extract the source code of the App, unless laws prohibit these restrictions or you have our written consent.
                    </p>
                </div>

                {/* 1 */}
                <br />
                <div className="tacPageTwoSubTitle">
                    Privacy Policy
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        Your use of the App is also governed by our Privacy Policy, which is incorporated into these Terms by this reference.
                        To view KidsKite's Privacy Policy, please click
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{color:'blue', textDecoration:'none'}}> here</a>.
                    </p>

                    {/* <p>
                        We reserve the right to modify these Terms at any time. Your continued use of the App following any such modifications signifies your acceptance of the updated Terms.
                        </p> */}
                </div>
                {/* 2 */}
                <div className="tacPageTwoSubTitle">
                    Third Party Content Pieces
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        The links to third-party websites provided on the Services are for your convenience. If you use these links, you are no longer using the Services. KidsKite has not reviewed these third-party websites, does not control them, and is not responsible for their content or availability. KidsKite does not endorse these websites or their content, nor does it make any representations about them or the results you may obtain from using them. If you choose to access any third-party websites linked to the Services, you do so at your own risk.
                    </p>
                    <p>
                        We cannot guarantee that other users will comply with these rules or any other provisions of these Terms of Use. Therefore, you assume all risk of harm or injury resulting from any lack of compliance by other users. You may only link to sections of the Services with the prior written consent of KidsKite and subject to the obligations set out in this Agreement. If you wish to do so, please contact KidsKite using the email id <span style={{ color: 'blue' }}>support@kidskite.app.</span>
                    </p>
                    {/* <p>
                        2) Any individuals under the age of 13 (or under the legal age of consent in your country) whose registration has not been authorized by a parent or legal guardian.
                        </p>
                        <p>
                        3) For school accounts, registration must be approved by school personnel as allowed under the Terms.
                        </p> */}
                </div>
                {/* 3 */}
                <div className="tacPageTwoSubTitle">
                    Purchases/Refund
                </div>
                <div className="tacPageTwoContent">

                   <p>1) <span style={{ fontWeight: "bold" }}>Subscription Process:</span> All subscriptions purchased through the App are subject to our acceptance. We may refuse or cancel orders at our sole discretion.</p>

                   <p>2) <span style={{ fontWeight: "bold" }}>Payment:</span> You agree to provide current, complete, and accurate purchase and account information for payments made through our App.</p>

                   <p>3) <span style={{ fontWeight: "bold" }}>Refunds and Cancellations:</span> No refunds shall be offered, except as expressly provided in these Terms or as required by law.</p>

                </div>
                {/* 4 */}
                <div className="tacPageTwoSubTitle">
                    Warranty
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        The App is provided on an "AS IS" and "AS AVAILABLE" basis. KidsKite makes no representations or warranties of any kind, express or implied, as to the operation of the App or the information, content, materials, or products included on the App.
                    </p>
                </div>
                {/* 5 */}
                <div className="tacPageTwoSubTitle">
                    Liability Disclaimer
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        Under no circumstances, including negligence, will KidsKite be responsible for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages. This includes damages resulting from an unsuccessful court action or legal dispute, lost business, lost revenues or profits, loss of data, or any other financial or non-financial loss or harm, even if KidsKite have been informed of the possibility of such damages.
                    </p>
                    <p>
                        Please be aware that applicable law may not permit the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not be applicable to you. In such cases, KidsKite's liability will be restricted to the maximum extent allowed by applicable law.
                    </p>
                </div>
                {/* 6 */}
                <div className="tacPageTwoSubTitle">
                    Indemnification
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        You agree to indemnify, defend, and hold harmless KidsKite, its officers, directors, employees, agents, licensors, suppliers, and any third-party information providers to the App from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the App using your account.
                    </p>
                </div>
                {/* 7 */}
                <div className="tacPageTwoSubTitle">
                    Termination
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        We may terminate your access to all or any part of the App at any time, with or without cause, with or without notice, effective immediately.
                    </p>
                </div>
                {/* 8 */}
                <div className="tacPageTwoSubTitle">
                    Dispute Resolution
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        Any disputes arising from or related to these Terms of Use, including issues regarding their existence, validity, or termination, that cannot be resolved amicably by the Parties within 15 days will be settled through arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996.
                    </p>
                    <p>
                        If the Parties cannot agree on a single arbitrator, a panel of three arbitrators will be appointed. Each Party will appoint one arbitrator within 15 days after the Consultation Period expires, and these two arbitrators will jointly select the presiding arbitrator within 15 days of the appointment of the second arbitrator.
                    </p>
                    <p>
                        The arbitration proceedings will take place in Delhi, and the arbitration will be conducted in the English language. A dispute will be considered to have arisen when one Party notifies the other Party in writing to that effect.
                    </p>
                </div>
                {/* 9 */}
                <div className="tacPageTwoSubTitle">
                    Miscellaneous
                </div>
                <div className="tacPageTwoContent">

                    <p>1) <span style={{ fontWeight: "bold" }}>Governing Law:</span> These Terms of Use are primarily governed by Indian laws. Any legal action, lawsuit, or other legal proceedings initiated to address any matter related to these Services will be under the jurisdiction of the courts in Delhi, India.</p>
                    <p>2) <span style={{ fontWeight: "bold" }}>Severability:</span> If any provision of these Terms is deemed unlawful, void, or for any reason unenforceable, then that provision shall be deemed Severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>

                    <p>3) <span style={{ fontWeight: "bold" }}>Entire Agreement:</span> These Terms constitute the entire agreement between you and KidsKite, concerning the App.</p>                </div>
                {/* 10 */}
                <div className="tacPageTwoSubTitle">
                    Updates
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        We have the discretion to change, modify, add, or remove parts of these Terms of Use without prior written notice to you. You should regularly review these Terms of Use to stay informed about any updates. If you continue to use the Services after we post changes, it means you accept and agree to the revised terms. As long as you comply with these Terms of Use, we grant you a personal, non-exclusive, non-transferable, limited privilege to access and use the Services.
                    </p>
                </div>
                {/* 11 */}
                <div className="tacPageTwoSubTitle">
                    Contact
                </div>
                <div className="tacPageTwoContent">
                    <p>
                        Gamesfly Technologies Private Limited
                    </p>
                    <p>
                        5th floor, H.B Twin Tower, Netaji Subhash Place,<br />
                        Pitampura, Delhi 110034<br />
                        Email Address: <span style={{ color: 'blue' }}>support@kidskite.app</span>
                    </p>
                </div>
            </div>
        </div>

    );
};

export default PageTwo;