// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redirecting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Ensure it takes full height of the viewport */
}
.redirecting-text {
  font-size: 36px;
  font-weight: bold;
  color: #de3a8c;
  /* Or any other color you prefer */
  animation: pulse 0.5s infinite alternate;
  /* Apply animation */
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Redirection/Redirection.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACF,gDAAgD;AAClD;AACA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;EACF,kCAAkC;EAAhC,wCAAA;EAEF,oBAAoB;AACtB;AAAA;EACI;IACI,mBAAA;EAEN;EAAE;IACI,qBAAA;EAEN;AACF","sourcesContent":[".redirecting-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Ensure it takes full height of the viewport */\n}\n\n.redirecting-text {\n    font-size: 36px;\n    font-weight: bold;\n    color: #de3a8c; /* Or any other color you prefer */\n    animation: pulse 0.5s infinite alternate; /* Apply animation */\n}\n\n@keyframes pulse {\n    0% {\n        transform: scale(1);\n    }\n    100% {\n        transform: scale(1.1);\n    }\n}\n\n@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
